//flag==true -> added
import localizeFilter from '@/filter/localize.filter'
export default function favourite(user, id, flag, cnt){
        this.$Progress.start()
        if(flag){
            this.$data.results[cnt].favourite = this.$data.results[cnt].favourite.replace(user + '++','')
        }else{
            this.$data.results[cnt].favourite = this.$data.results[cnt].favourite + user + '++'
        }
        axios.get('/catalic.php', 
            {
                params: 
                {
                    type: 3,
                    id: id,
                    favourite: this.$data.results[cnt].favourite,
                }
            })
            .then(response => {
                if(response.data != -10){
                    if(flag){
                        UIkit.notification({message: localizeFilter('UnsetFavourite'), pos:'bottom-right'})
                    }else{
                        UIkit.notification({message: localizeFilter('SetFavourite'), pos:'bottom-right'})
                    }
                }else{
                    UIkit.notification({message: localizeFilter('Fatal_error'), status:'danger'})
                }
                this.$Progress.finish()
            })
            .catch(error =>{
                console.log(error)
                UIkit.notification({message: localizeFilter('Fatal_error_axios'), status:'danger'})
                this.$Progress.fail()
            })
}