<template>
<div>
        <!-- <vue-topprogress ref="topProgress"></vue-topprogress> -->
    <div v-if="this.$data.results.length === 0" class="uk-alert-danger" uk-alert>
            <p>{{'EmptySearch' | localize}}</p>
    </div>
    <form id="search-form" @submit.prevent="PageSearch(search, false)" class="uk-margin-bottom">
        <div class="uk-grid uk-grid-small">
            <div class="uk-inline uk-width-4-5@s">
                <span class="uk-form-icon uk-form-icon-flip" data-uk-icon="icon: search"></span>
                <input class="uk-input uk-width-1-1 search-fld boundary-align" type="text" :placeholder="localizeFilter('Placeholder_serach')" v-model="search" @input="PageSearch(search)">
                <div uk-dropdown="pos: bottom-justify; boundary: .boundary-align; boundary-align: true;" id="preview">
                    <div v-if="this.$data.preview != ''">
                        <tbody v-for="(result, name) in preview"  :id="result.id">
                            <tr>
                                <td>
                                    <!-- // PRODUCTION
                                    <img class="uk-prexserve-width" width="50" :src="'http://autocat.ru/images/'+[result.photopass==='1' ? result.id : 'error']+'/'+[result.photopass==='1' ? result.images[2] : 'noimage.jpg']">
-->
                                    <img class="uk-prexserve-width" width="50" :src="'http://autocatcatalog.com/images/'+[result.photopass==='1' ? result.id : 'error']+'/'+[result.photopass==='1' ? result.images[2] : 'noimage.jpg']">
                                </td>
                                <td class="uk-table-link">
                                    <a class="uk-text-emphasis" v-on:click="ShowModal([result.mark, result.model, result.generation, result.engine_code, result.pt, result.pd, result.rh, result.type, result.weight, result.country, result.images, result.id, result.photopass, result.tags])">
                                        {{result.mark | trimBracket}} {{result.model}} {{result.generation}} {{result.engine_code}}
                                    </a>
                                </td>
                                <td>{{result.catalic_code || '-'}}</td>
                            </tr>
                        </tbody>
                    </div>
                </div>
            </div>
            <div class="uk-width-expand">
                <button class="uk-button uk-width-1-1 uk-visible@s uk-button-primary uk-border-rounded">{{"Search" | localize}}</button>
            </div>
        </div>
    </form>
     <div id="change-show" :class="{'uk-child-width-1-3@s' : list == false}" :uk-grid="list ? false : 'masonry:true'">
    <!-- <div id="change-show" class="uk-grid uk-child-width-1-3@s" uk-grid="masonry: true"> --> <!-- УБРАТЬ ДОБАВИТЬ uk-grid="masonry: true" -->

        <div v-for="(result, cnt) in results"   class="makeList">
            <div class="uk-card-default" :class="{'uk-margin uk-grid-collapse uk-grid' : list == true}" style="position:relative"> <!-- УБРАТЬ ДОБАВИТЬ UK-GRID -->
                <div class="uk-position-small uk-position-bottom-right uk-overlay-default uk-dark" :class="{'uk-position-top-right' : list==true}">
                    <a uk-icon="icon:star" :id="result.id" @click="favourite(userid, result.id, result.favourite.split('++').includes(userid), cnt)" :style="{color : result.favourite.split('++').includes(userid) ? '#FFCC33' : ''}" class="uk-icon-link uk-icon-button"></a>
                </div>
            <!-- NONE <div v-if="!list" class="uk-card-default"> -->
                <!-- <div class="mobile-padding-figure uk-card-media-top uk-cover-container uk-width-1-4@s" uk-lightbox> -->
                <div class="mobile-padding-figure uk-card-media-top uk-cover-container" :class="{'uk-width-1-4@s' : list==true}" uk-lightbox>
                    <figure :class="{'uk-fig' : list == true}">

                        <a :href="'https://autocatcatalog.com/images/'+[result.photopass==='1' ? result.id : 'error']+'/'+[result.photopass==='1' ? result.images[2] : 'noimage.jpg']">
                            <img :src="'https://autocatcatalog.com/images/'+[result.photopass==='1' ? result.id : 'error']+'/'+[result.photopass==='1' ? result.images[2] : 'noimage.jpg']" :class="{'uk-img' : list == true}" style="max-width: 100%; max-height: 100%;">
                        </a>

                        <!-- PRODUCTION

                        <a :href="'http://autocat.ru/images/'+[result.photopass==='1' ? result.id : 'error']+'/'+[result.photopass==='1' ? result.images[2] : 'noimage.jpg']">
                            <img :src="'http://autocat.ru/images/'+[result.photopass==='1' ? result.id : 'error']+'/'+[result.photopass==='1' ? result.images[2] : 'noimage.jpg']" :class="{'uk-img' : list == true}" style="max-width: 100%; max-height: 100%;">
                        </a>
-->
                    </figure>
                    <div>
                        <div :class="{'uk-hidden@s' : list == true}" class="uk-card-badge uk-label" style="opacity:60%; text-transform:none; font-size:0.78rem"> {{localizeFilter('Kg')}}: {{price([result.pt, result.pd, result.rh, result.type]) | tofix(result.type) | formatPrice}} </div>
                        <div :class="{'uk-hidden@s' : list == true}" class="uk-card-badge uk-label" style="background-color:#f01e1e; opacity:60%; margin-top:25px;  text-transform:none; font-size:0.78rem"> {{localizeFilter('Part')}}: {{price([result.pt, result.pd, result.rh, result.type]) * result.weight / 1000 | tofix(result.type) | formatPrice}} </div>
                    </div>
                    <!--
                    <div class="uk-card-badge uk-label" :class="{'uk-hidden@s' : list == true}">  {{price([result.pt, result.pd, result.rh]) | tofix | formatPrice}} / {{price([result.pt, result.pd, result.rh]) * result.weight / 1000 | tofix | formatPrice}} </div>
                    -->
                </div>
                <!-- <div class="mobile-padding-content uk-card-body uk-margin-xsmall-left uk-width-3-4@s" uk-grid id=""> -->
                <div class="mobile-padding-content uk-card-body uk-margin-xsmall-left uk-padding-small" :class="{'uk-width-3-4@s uk-grid' : list == true}" uk-grid>
                    <!-- <div class="hidden-el-3 uk-width-3-4@s"> -->
                    <div class="hidden-el-3" :class="{'uk-width-3-4@s' : list == true}">
                        <a v-on:click="ShowModal([result.mark, result.model, result.generation, result.engine_code, result.pt, result.pd, result.rh, result.type, result.weight, result.country, result.images, result.id, result.photopass, result.tags, result.comment, result.specs, result.catalic_code])">
                            <h4 class="uk-text-bold">{{result.mark | trimBracket}} {{result.model}} {{result.generation}} {{result.engine_code}}</h4>
                        </a>
                        <ul class="uk-list uk-margin-remove-top uk-text-small uk-text-emphasis">
                            <li> <span class="uk-text-muted">{{"EngineVolume" | localize}}:</span> {{result.engine || '-'}}</li>
                            <li> <span class="uk-text-muted">{{"Catalic_code" | localize}}:</span> {{result.catalic_code || '-'}} </li>
                            <li> <span class="uk-text-muted">{{"Comment" | localize}}:</span> {{result.comment || '-'}} </li>
                            <li> <span class="uk-text-muted">{{"Description" | localize}}:</span> {{result.specs || '-'}} </li>
                        </ul>
                    </div>
                    <!-- <div id="" class="hidden-el-1 uk-visible@s uk-width-1-4@s" style="border-left: 1px solid #e5e5e5;"> -->
                    <div id="" class="hidden-el-1 uk-visible@s uk-width-1-4@s" :class="{'uk-hidden' : list == false}" style="border-left: 1px solid #e5e5e5;">
                        <ul class="uk-list uk-margin-small-top uk-text-small uk-text-emphasis">
                            <li class="uk-visible@s"> <span class="uk-text-muted">{{"Weight" | localize}}:</span> {{result.weight || '-'}}</li>
                            <li class="uk-visible@s uk-nav-divider"></li>
                            <li class="uk-visible@s"> <span class="uk-text-muted">{{"Country" | localize}}</span> {{result.country | localize}}</li>
                            <!-- <li class="uk-visible@s"> <span class="uk-text-muted">{{"Country" | localize}}</span> {{result.country | localize}}</li> -->
                            <li class="uk-visible@s uk-nav-divider"></li>
                            <li class="uk-visible@s"> <span class="uk-text-muted">{{"Kg" | localize}}:</span> {{price([result.pt, result.pd, result.rh, result.type]) | tofix(result.type) | formatPrice}} </li>
                            <li class="uk-visible@s"> <span class="uk-text-muted">{{"Part" | localize}}:</span> {{price([result.pt, result.pd, result.rh, result.type]) * result.weight / 1000 | tofix(result.type) | formatPrice}}</li>
                        </ul>

                    </div>
                </div>
            </div>
        </div>
            <!-- Modal -->
        <div id="modal-center-one" class="uk-flex-top uk-modal-container" uk-modal>
        <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            <button class="uk-modal-close-default" type="button" uk-close></button>
            <div class="uk-card-default uk-grid-collapse uk-child-width-1-2@s " uk-grid>
                <!-- SLIDE SHOW -->
                <div class="uk-position-relative uk-visible-toggle" uk-slideshow>
                    <ul class="uk-slideshow-items" style="min-height:100%">
                        <li v-for="image in modal[10]" uk-lightbox>
                            <a :href=" noimage + modal[11] +'/'+image">

                                <img :src=" noimage + modal[11] +'/'+image" alt=" " uk-cover>
                                <!--
                                 <img :src=" noimage + modal[11] +'/'+image" alt=" " style="max-height:100%; max-width:100% ; position: relative; left: 50%; top: 50%; transform: translate(-50%,-50%)">
                                -->
                            </a>
                        </li>
                    </ul>
                    <a class="uk-position-center-left uk-position-small uk-hidden-hover" href="#" style="color:blue" uk-slidenav-previous uk-slideshow-item="previous"></a>
                    <a class="uk-position-center-right uk-position-small uk-hidden-hover" href="#" style="color:blue" uk-slidenav-next uk-slideshow-item="next"></a>

                    <div>
                        <div class="uk-hidden@s uk-card-badge uk-label" style="opacity:60%; text-transform:none; font-size:0.78rem"> {{localizeFilter('Kg')}}: {{price([modal[4], modal[5], modal[6], modal[7]]) | tofix(modal[7]) | formatPrice}} </div>
                        <div class="uk-hidden@s uk-card-badge uk-label" style="background-color:#f01e1e; opacity:60%; margin-top:25px; text-transform:none; font-size:0.78rem"> {{localizeFilter('Part')}}: {{price([modal[4], modal[5], modal[6], modal[7]]) * modal[8] / 1000| tofix(modal[7]) | formatPrice}} </div>
                    </div>

                </div>
                <!-- END SLIDE SHOW -->
                <div>
                    <div class="uk-dark uk-card-body">
                        <h2 class="uk-card-title uk-text-bold" style="margin-bottom:0px">{{this.$data.modal[0]| trimBracket}} {{this.$data.modal[1]}} {{this.$data.modal[2]}} {{this.$data.modal[3]}}</h2>
                        <div class="uk-grid-divider" uk-grid><h4 class="uk-visible@s uk-text-bold" style="color:blue">{{localizeFilter('Kg')}}: {{price([modal[4], modal[5], modal[6], modal[7]]) | tofix(modal[7]) | formatPrice}}</h4> <h4 class="uk-visible@s uk-text-bold" style="color:red">{{localizeFilter('Part')}}: {{price([modal[4], modal[5], modal[6], modal[7]]) * modal[8] / 1000| tofix(modal[7]) | formatPrice}} </h4></div>
                        <div class="uk-child-width-expand@s" uk-grid style="margin-top:20px">
                            <div>
                                <ul class="uk-list uk-text-medium uk-text-emphasis uk-list-large">
                                    <li class = "uk-hidden@s"> <span class="uk-text-muted">{{"Catalic_code" | localize}}:</span> {{this.$data.modal[16] || '-'}} </li>
                                    <li> <span class="uk-text-muted">{{'Type' | localize}}:</span> {{localizeFilter(this.$data.modal[7])}} </li>
                                    <li> <span class="uk-text-muted">{{'Weight' | localize}}:</span> {{this.$data.modal[8]}} </li>
                                    <li> <span class="uk-text-muted">{{'Country' | localize}}:</span> {{this.$data.modal[9] | localize}} </li>
                                    <li class = "uk-hidden@s"> <span class="uk-hidden@s uk-text-muted">{{"Comment" | localize}}:</span> {{this.$data.modal[14] || '-'}} </li>
                                    <li class = "uk-hidden@s"> <span class="uk-text-muted">{{"Description" | localize}}:</span> {{this.$data.modal[15] || '-'}} </li>
                                </ul>
                            </div>
                            <div style="margin-top:20px">
                                <ul class="uk-list uk-text-medium uk-text-emphasis uk-list-large" v-if="['1','3','4','5'].includes(this.$data.role)">
                                    <li> <span class="uk-text-muted">{{'Pt' | localize}}:</span> {{this.$data.modal[4] || 0}} % </li>
                                    <li> <span class="uk-text-muted">{{'Pd' | localize}}:</span> {{this.$data.modal[5] || 0}} % </li>
                                    <li> <span class="uk-text-muted">{{'Rh' | localize}}:</span> {{this.$data.modal[6] || 0}} % </li>
                                </ul>
                            </div>

                        </div>
                        <div class="uk-text-meta uk-margin-medium-top">
                                {{this.$data.modal[13] ? '#'+this.$data.modal[13].replaceAll(' ', ' #') : ''}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
 </div>
 <div id="mod"> </div>
</div>

</template>

<script>
import {eventBus} from '@/main'
import localizeFilter from "@/filter/localize.filter"
import tofix from "@/filter/fix.filter"
import formatPrice from "@/filter/format.filter"
import store from '@/store'
import ShowModal from '@/config/ShowModal.general'
import PageSearch from '@/config/PageSearch.general'
import price from '@/config/Price.general'
import trimBracket from '@/filter/trimBracket.filter'
import favourite from '@/config/Favourite.general'

export default {
    metaInfo:{
        title: localizeFilter('TitleResult')
    },
    data: () => ({
        search: '',
        results: '',
        modal: '',
        allresults: '',
        images: '',
        //noimage: 'http://autocat.ru/images/',
        // PRODUCTION
        noimage: 'https://autocatcatalog.com/images/',
        role: $cookies.get('role'),
        list: true,
        fun: '',
        preview: '',
        userid : $cookies.get("applicationRevId")
    }),
    methods:{
        PageSearch,
        ShowModal,
        localizeFilter,
        price,
        trimBracket,
        favourite
    },
    beforeMount(){
        if(this.$route.params['search']){
            this.$data.search = this.$route.params['search']
            this.PageSearch(this.$data.search, false)
        }
        else if(this.$route.params['filter']){
            this.$data.search = trimBracket(this.$route.params['filter'].text)
            this.PageSearch(this.$data.search, false)
            eventBus.$emit('setFilter', {
                    mark: this.$route.params['filter'],
                })
        }
    },
    created(){
        //console.log(this.results);

        eventBus.$on('gofilter', data => {

            let t = ''
            for(var key in data){
                if(data[key] != undefined && data[key] != ''){
                    t += data[key].split(' ')[0] + ' ';
                }
            }
            this.PageSearch(t, false);
            this.$data.search = t;

            //console.log(t);
            //console.log(data.forEach(([key, value]) => console.log(`${key}: ${value}`)));
            /*
             if(this.allresults != ''){
                var f = this.allresults
                this.results = f.filter( function(i){
                    for(var key in data){
                        if(data[key] != undefined && data[key] != ''){
                            if(key == 'favourite'){
                                if(!i[key].split('++').includes(data[key])){
                                    return false
                                }
                            }

                            else if(key != 'engine'){
                                if(i[key] === undefined || i[key] != data[key])
                                    return false
                            }
                            else{
                                if(!((data[key][0] == 0) && (data[key][1] == 10)))
                                {
                                    if(i[key] != undefined){
                                        if(!((i[key] >= data[key][0]) && (i[key] <= data[key][1])))
                                        {
                                            return false
                                        }
                                    }else{
                                        return false
                                    }
                                }
                            }

                        }
                    }
                    return true
                })
            }
             */

            eventBus.$on('clearfilter', () => {
                this.results = this.allresults
            })
        }),
        eventBus.$on('list',() => {
                this.list = true,
                this.$el.querySelectorAll('.makeList').forEach((x) => {
                    x.style = ''
                    x.className = 'makeList'
                })
                this.$el.querySelector('#change-show').style = ''
        })
        eventBus.$on('grid',() => {
                this.list = false
        })
         eventBus.$on('favourite', data => {
            if(this.allresults != ''){
                var f = this.allresults
                this.results = f.filter( function(i){
                    for(var key in data){
                        if(data[key] != undefined && data[key] != ''){
                            if(key == 'favourite'){
                                if(!i[key].split('++').includes(data[key])){
                                    return false
                                }
                            }
                        }
                    }
                    return true
                })
            }
        })

    },
    filters:{
        tofix, formatPrice
    }
}
</script>
